'use strict';
var processInclude = require('base/util');
processInclude(require('./jquery.datetextentry'));
var errorLabel = require('../errorLabels');

var now = new Date();
var $year = now.getFullYear() - 18;
var $month = '' + now.getMonth();
var $date = '' + now.getDate();

if ($month.length < 2) {
    $month = '0' + $month;
}
if ($date.length < 2) {
    $date = '0' + $date;
}
var eighteenYears = [$year, $month, $date].join('-');

function initCustomSelect() {
    $(document).ready(function () {
        // Get all elements with the class "custom-select"
        var $customSelects = $(".custom-select");
    
        // Loop through each "custom-select" element
        $customSelects.each(function () {
            var $this = $(this);
            var $selectElement = $this.find("select").first();
            var $selectedDiv = $("<div>", { "class": "select-selected" });
            var $optionsDiv = $("<div>", { "class": "select-items select-hide" });
    
            // Set the initial selected option
            $selectedDiv.html($selectElement.find("option:selected").val() || '');
            $this.append($selectedDiv);
    
            // Loop through each option in the select element (starting from the second option)
            $selectElement.find("option").each(function (index) {
                if (index > 0) {
                    var $option = $(this);
                    var $optionDiv = $("<div>").html($option.html());
                    var disabled = $option.attr('disabled');
                    $optionDiv.addClass(disabled ? 'disabled' : '');
    
                    // Set the data-value attribute of the optionDiv to the value of the option
                    $optionDiv.attr('data-value', $option.val());
    
                    // Add click event listener to update the original select box and the displayed selected option
                    $optionDiv.on("click", function () {
                        if (!$(this).hasClass('disabled') && !$(this).hasClass('same-as-selected')) {
                            var $selectBox = $(this).closest('.custom-select').find("select").first();
                            var $selectedDiv = $(this).closest('.select-items').prev('.select-selected');
    
                            $selectBox.find("option").each(function (i) {
                                if ($(this).html() == $optionDiv.html()) {
                                    $selectBox.prop('selectedIndex', i);
                                    $selectedDiv.html($optionDiv.attr('data-value'));
                                    $optionDiv.siblings('.same-as-selected').removeClass('same-as-selected');
                                    $(this).prop('selected', true);
                                    $optionDiv.addClass("same-as-selected");
                                    $selectBox.trigger('change');
                                    return false;
                                }
                            });
                            $selectedDiv.click();
                        }
                    });
                    $optionsDiv.append($optionDiv);
                }
            });
            $this.append($optionsDiv);
    
            // Add click event listener to toggle the visibility of the option list
            $selectedDiv.on("click", function (e) {
                e.stopPropagation();
                closeAllSelect(this);
                $(this).next(".select-items").toggleClass("select-hide");
                $(this).toggleClass("select-arrow-active");
            });
        });
    
        // Function to close all select boxes except the current one
        function closeAllSelect(elmnt) {
            var $items = $(".select-items");
            var $selectedItems = $(".select-selected");
            $selectedItems.not(elmnt).removeClass("select-arrow-active");
            $items.not($(elmnt).next()).addClass("select-hide");
        }
    
        // Close all select boxes if the user clicks outside
        $(document).on("click", closeAllSelect);
    });
};

module.exports = {
    initForm: function() {
        if ($('.edit-profile-form, .registration, .js-add-payment-form, #contact-us-id').length > 0) {
            initCustomSelect();
        }
        $(document).on('focus', 'input.form-control:not([type=hidden])', function() {
            var $this = $(this);
            var $parent = $this.closest('.form-group');
            var $label = $parent.find('label');
            $parent.addClass('in-focus');
            $label.addClass('active');
        });
        
        $(document).on('blur', 'input.form-control:not([type=hidden])', function() {
            var $this = $(this);
            var $parent = $this.parent();
            var $label = $parent.find('label');
            var $val = $this.val();
            
            if ($val.length === 0) {
                $label.removeClass('active');
            }
            $parent.removeClass('in-focus');
        });

        $(document).on('click', 'label.form-control-label', function() {
            var $this = $(this);
            var $parent = $this.closest('.form-group');
            var $label = $this;
            $parent.addClass('in-focus');
            $label.addClass('active');
            $parent.find('.form-control').focus();
        });
       
        $(document).ready(function(){
            $('.form-group input:not([type=hidden])').each(function() {
                var $this = $(this);
                var $parent = $this.parent();
                var $label = $parent.find('label');
                var $val = $this.val();
                if ($val.length > 0) {
                    $label.addClass('active');
                    $parent.addClass('in-focus');
                }
            })            
        });
    },
    radioButton: function () {
        $(document).on('click', '.js-radio-trigger .input-field', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $data = $this.data('type');
            var $input = $this.parent().find('input');
            $this.addClass('selected').siblings().removeClass('selected');
            $input.val($data);
        });
    },
    onlyNumbers: function () {
        $('.js-number-only').on('keypress', function (e) {
            var charCode = (e.which) ? e.which : event.keyCode;
            if (String.fromCharCode(charCode).match(/[^0-9]/g))
                return false;
        });    
    },
    revealPassword: function () {
        $(document).on('click', '.js-reveal-password', function (e) {
            var $this = $(this);
            var $parent = $this.parent();
            var $input = $parent.find('.form-control');
            var $type = $input.attr('type');

            if ($type === 'password') {
                $input.attr('type', 'text');
                $this.text($this.data('hide'));
            } else {
                $input.attr('type', 'password');
                $this.text($this.data('show'));
            }
        })
    },
    dob: function () {
        $('#js-birthday').datetextentry({
            'field_width_day': 18,
            'field_width_month': 18,
            'field_width_year': 85,
            'show_errors': false,
            'max_date': eighteenYears,
            'max_date_message': 'You should be above 18 years of age',
            'show_tooltips': false,
            'on_error': function (msg) {
                if (msg !== '') {
                    $('label.date-label').addClass('is-invalid').text(msg);
                    $('label.date-label').closest('.form-group').addClass('is-invalid');
                } else {
                    var datafiled = $('label.date-label').data('label');
                    $('label.date-label').removeClass('is-invalid').text(datafiled);
                    $('label.date-label').closest('.form-group').removeClass('is-invalid');
                }
            },
            'E_BAD_DAY_FOR_MONTH': 'Only %d days in %m',
            'E_DAY_NAN': 'Day must be a number',
            'E_DAY_TOO_BIG': 'Day must be 1-31',
            'E_DAY_TOO_SMALL': 'Day must be 1-31 ',
            'E_MAX_DATE': 'Date must not be later than %DATE',
            'E_MIN_DATE': 'Date must not be earlier than %DATE ',
            'E_MONTH_NAN': 'Month must be a number',
            'E_MONTH_TOO_BIG': 'Month must be 1-12',
            'E_MONTH_TOO_SMALL': 'Month must be 1-12',
            'E_REQUIRED_FIELD': 'This field is required',
            'E_YEAR_LENGTH': 'Year must be 4 digits',
            'E_YEAR_NAN': 'Year must be a number',
            'E_YEAR_TOO_BIG': 'Year must not be after %y',
            'E_YEAR_TOO_SMALL': 'Year must not be before %y'
        });
    },
    emailMissing: function () {
        $('form#emailMissingForm').validate();

        $('#emailMissingForm #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });

        $('#emailMissingForm').on('submit', function (e) {
            e.preventDefault();
            if (!$(this).valid()) {
                return false;
            }
            var url = $(this).attr('action');
            var data = $(this).serialize();
            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                success: function (response) {
                    if (response.success) {
                        $('.modal-backdrop.fade.show').remove();
                        $('.modal.fade.show').removeClass('show');
                    } else {
                        $('.js-customer-email-error').removeClass('d-none');
                        $('.js-customer-email-error').text(response.message);
                    }
                }
            });
        });
    }
};
