// @ts-nocheck
'use strict';
import Swiper from 'swiper/bundle';

var mySwipers = new Swiper(".mobileMainCarousel", {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 12,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: true,
    preloadImages: false,
    watchSlidesVisibility: false,
    watchOverflow: true,
    autoplay: {
        delay: 3000,
    },
    // If we need pagination
    pagination: {
        el: '.mobileMainCarousel .swiper-pagination',
    },
    navigation: {
        nextEl: '.mobileMainCarousel .swiper-button-next',
        prevEl: '.mobileMainCarousel .swiper-button-prev',
    },
});
var mySwiper = new Swiper(".category-list", {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 7,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: true,
    preloadImages: false,
    watchSlidesVisibility: false,
    preventClicks: false,
    preventClicksPropagation: false
});
